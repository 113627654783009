<template>
  <div v-if="config" :class="{ hideMouse: !debugging}">
    <!-- DEBUG Info -->
    <section class="hero is-info is-small spaceDebug" v-show="debugging">
      <div class="hero-body has-background-primary">
        <div class="columns">
          <div class="column is-4">
            <h1 class="title">{{ experience?.title }}</h1>
            <h1 class="subtitle">{{ space.title }}</h1>
            <b-tag type="is-info is-light" size="is-medium">{{ experience ?
                $camelToWords(experience?.component[0].blockType) : ''
            }}
            </b-tag>
            <div>
              {{ $t('system.debugServerState') }}: {{ $store.getters["space/serverStateString"] }}
              <button v-if="$store.state.space.serverState == 2" @click="MatchFinish()">
                {{ $t('system.debugEndMatch') }}
              </button>
            </div>
            <div style="display: inline-block;"
              :class="{ 'has-background-warning-light has-text-warning-dark': connectedScreens > 1 }">
              {{ $t('system.debugConnectedScreens') }}: {{ connectedScreens }}
            </div>
            <div> {{ $t('system.debugVolume') }}: {{ space.volume }}</div>
          </div>
          <div class="column is-6">
            <div style="overflow-y: auto; height: 200px" v-if="debugShowJson">
              <pre>{{ experience?.component[0] }}</pre>
            </div>
          </div>
          <div class="column">
            <b-button class="mb-2" expanded @click="ToggleFullscreen()" pack="fas" size="is-small">
              <d-icon pack="fas" icon="expand" />&nbsp; {{ $t('system.debugFullscreen') }}
            </b-button>
            <b-button expanded @click="SwitchSpace()" pack="fas" size="is-small">
              <d-icon pack="fas" icon="rocket" />&nbsp; {{ $t('system.debugScwitchSpace') }} 
            </b-button>
          </div>
        </div>
      </div>
    </section>

    <component v-if="experience" :is="GetComponent" :options="experience.component[0]" ref="ScreenComponent"
      :key="experience.id" @hook:mounted="ScreenComponentMounted">
    </component>

    <template v-if="spaceWithoutExperience">
      <div id="spaceWithoutExperience">
        {{ $t('system.voidSpace') }} ({{ space.title }})
      </div>
    </template>

    <div id="screenIdentification" v-if="showScreenIdentification"
      class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
      :style="{ '--section-color': space.section.color }" ref="screenId">
      <div class="collapsable" ref="screenIdCollapsable" :style="{ '--screenIdWidth': screenIdWidth }"
        :class="{ collapsed: this.players > 0 }">
        <div class="category is-flex is-align-items-center is-justify-content-center">
          <div class="circle">
            <d-icon v-if="!!config && experience?.category.icon" :pack="experience.category.icon.pack"
              :icon="experience.category.icon.name" />
          </div>
        </div>
        <div class="experience-title">
          <div class="mx-2 px-5">{{ experience?.title }}</div>
        </div>
      </div>
      <div class="space is-flex is-align-items-center is-justify-content-center has-text-weight-bold">
        <div>
          {{ space.alphanumeric }}
        </div>
      </div>
    </div>

    <screenReactions/>

  </div>
  <!-- <div v-else style="font-size: 3rem; display: flex; justify-content: center; align-items: center; text-align: center;">
    SPACE NOT IN PLAYLIST</div> -->
</template>

<script>
import router from "../router";
import screenReactions from "../components/screens/screenReactions.vue";
import axios from "axios";

export default {
  components: {
    error: () => import("@/components/screens/screenErrorController.vue"),
    videoSelector: () => import("@/components/screens/screenVideoSelector.vue"),
    infographic: () => import("@/components/screens/screenInfographic.vue"),
    trivia: () => import("@/components/screens/screenTrivia.vue"),
    infoSpot: () => import("@/components/screens/screenInfoSpot.vue"),
    external: () => import("@/components/screens/screenExternal.vue"),
    questionsBox: () => import("@/components/screens/screenChroma.vue"),
    wordcloud: () => import("@/components/screens/screenWordCloud.vue"),
    defenseGame: () => import("@/components/screens/screenDefenseGame.vue"),
    raffle: () => import("@/components/screens/screenRaffle.vue"),
    screenReactions,
    
  },
  data() {
    return {
      connectedOnce: false,
      playerLength: 0,
      screenIdWidth: 'auto',
      debugging: false,
      debugShowJson: false,
      debugHideTimeout: null,
      connectedScreens: 0,
      hostControl: false,
      mouseX: 0,
      mouseY: 0,
      reactions: [],
      workerController: null,
    };
  },
  computed: {
    spaceWithoutExperience() {
      const state = this.playlist.find(x => x.space.id == this.space.id) === undefined
      if (state) this.$store.commit("StopLoading", null, { root: true })
      if (state && !this.$socket.client.connected) this.ScreenComponentMounted()
      return state
    },
    GetComponent() {
      if (this.experience.component.length == 0) return "error"

      const components = Object.keys(this.$options.components)
      const blockType = this.experience.component[0].blockType

      if (!components.includes(blockType)) {
        console.warn(`El componente ${blockType} no está registrado`)
        return "error"
      }
      return blockType
    },
    config() {
      return this.$store.getters["space/config"]
    },
    HideDebugger() {
      return this.$store.state.setup.others?.hideDebugger
    },
    showDebugger() {
      return this.HideDebugger() && this.debugging
    },
    experience() {
      return this.config.experience
    },
    space() {
      return this.config.space
    },
    playlist() {
      return this.$store.getters.playlist
    },
    players() {
      return this.$store.state.space.players.filter(x => !x.queue).length
    },
    showScreenIdentification() {
      return !(this.experience.hideScreenIdentification || this.space.alphanumeric?.trim() == '')
    }
  },
  methods: {
    ScreenComponentMounted() {
      console.log("screen mount", process.env.NODE_ENV)
      
      if (!this.$socket.client.connected) {
        console.log("opening socket connection")
        this.$socket.client.open()
      }
      // Hardcode de estilos que estaria bueno quitar (pero para eso deberíamos cambiar el tipo de animación)
      if(this.showScreenIdentification && this.$refs.screenId) {
        this.screenIdWidth = this.$refs.screenId.clientWidth - 123 + 'px'
      }

    },
    KeyListener(evt) {

      if (evt.key == " " || evt.code == "Space" || evt.keyCode == 32) {
      
        if(this.HideDebugger) return false
      
        if (evt) {
          this.mouseX = evt.clientX
          this.mouseY = evt.clientY
        }

        this.debugging = !this.debugging;
      }

      if(evt.key === 'PageDown' || evt.key === 'ArrowRight') {
          this.ForceStartGame()
      }

    },
    ToggleFullscreen() {
      console.log("Toggle fullscreen")

      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen()
      } else {
        if (document.exitFullscreen) document.exitFullscreen()
      }
    },
    ForceStartGame() {
      if(this.$store.state.space.serverState != 1) return
      this.$socket.client.emit("ForceStartGame", { spaceId: this.config.space.id })
    },
    MatchFinish() {
      // TODO call component func
      this.$socket.client.emit("space:MatchFinish", {
        spaceSlug: this.$store.state.space.spaceSlug,
      });
    },
    SwitchSpace() {
      localStorage.removeItem("defaultSpace")
      router.push("/spaces")
    },
    PreloadShortcutAssets(){
      console.log("⚡️ Preloading Shortcut Assets")
      const room = this.playlist.find(x => x.space.id == this.space.id)
      // console.log("&&&&", room.shortcutExperiences)
      if(!room.shortcutExperiences){ return }
      const apiUrls = room.shortcutExperiences.map(id => {return  process.env.VUE_APP_API_BASE + "/api/experiences/" + id})
      const requests = apiUrls.map(url => axios.get(url));

      Promise.all(requests)
        .then(responses => {
          responses.forEach(response => {
            const component = response.data.component[0]
            if(!component){ return; }
            
            // console.log("Preloading", response.data.title, component)
            var assets = [];
            switch(component.blockType){
              case "videoSelector":
                assets = [
                  ...component.standByVideos.map(x => x.video.url),
                  ...component.videos.map(x => x.video.url),
                  "/assets/videoselector/tutorial.png"
                ]
                break;
              case "infographic":
                assets = [
                  ...Object.values(component.sounds).map(x=>x.url),
                  ...component.standByVideos.map(x => x.video.url),
                  ...component.points.filter(x => x.video).map(x => x.video.url),
                  "/assets/infographic/tutorialscreen.png",
                ]
                break;
              case "trivia":
                assets = [
                  ...component.standByVideos.map(x => x.video.url),
                  ...component.transitions.map(x => x.animation.url),
                  ...component.questions.map(x => x.image.sizes.high.url),
                  ...component.questions.map(x => x.image.url),
                ]
                component.animations.forEach(anim => {
                  anim.states.forEach(state => {
                    assets.push(state.animation.url)
                  })
                })
                break;
            }
            // console.log("Preleading the following assets", assets)
            assets.forEach((asset) => {
              this.workerController.postMessage({
                action: 'cache-asset',
                videoURL: asset
              });
            })
          });
        })
        .catch(error => {
          console.error(error);
        });

    }
  },
  watch: {
    reactionsLength(newVal, oldVal) {
      if (newVal > oldVal) {
        setTimeout(() => {
          this.reactions.pop()
          console.log("se elimino una reaccion")
        }, 3000)
      }
    }
  },
  sockets: {
    CloseDuplicateScreen(){
      router.push("/duplicatescreen")
    },
    SetupEdited() {
      console.log("Setup edited")
    },
    hostControlState(data) {
      console.log("🎭 Host control changed", data.state)
      this.hostControl = data.state;
    },
    connectedScreensUpdate(data) {
      this.connectedScreens = data.screensConnected
    },
    playerJoined() {
      this.$sfxPlay('newplayer')
    },
    playerLeft() {
      this.$sfxPlay('playerleave')
    },
    connect() {
      if (this.connectedOnce) window.location.reload() // Si estoy reconectando, recargar la pagina
      
      this.connectedOnce = true
      console.log("socket.io connect")
      
      this.$sfxLoad()
      const spaceSlug = this.$route.params.slug
      this.$socket.client.emit("space:LoginServer", { spaceSlug, api: "bla" })
      this.$store.commit("StopLoading", null, { root: true })
    },
    experienceChanged(){
      console.log("Experience changed")
      window.location.reload();
    }

  },
  async mounted() {
    const spaceSlug = this.$route.params.slug
    this.$store.commit("space/SpaceSlug", spaceSlug)
    this.debugShowJson = !this.HideDebugger

    this.$sfxLoad()

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker?.getRegistration("../../CacheServiceWorker.js")
        .then(registration => {
          return registration.active;
        })
        .then(controller => {
          this.workerController = controller
        })
        .catch(error => {
          console.error('Error getting Service Worker registration:', error);
        });
    
      // En 5 segundos empiezo a precargar los shortcuts
      setTimeout(()=>{
        this.PreloadShortcutAssets()
      }, 5000)

    }
    setTimeout(() => {
      this.$store.commit("SetdisableReconnectingAlert", false);
    }, 1000);
    document.addEventListener("keydown", this.KeyListener); //el debugger se activa presionando la barra
  },
  beforeDestroy() {
    console.log("logout before destroy")
    // this.$socket.client.emit("space:Logout")
    this.$socket.client.close()
    this.$store.commit("space/SpaceSlug", "")

    document.removeEventListener("keydown", this.KeyListener)
  },
};
</script>
<style lang="scss">
@import '@/styles/variables.scss';

.spaceDebug {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  opacity: 0.8;
}

$screenIdHeight: 123px;

#screenIdentification {
  $height: $screenIdHeight;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: $height;
  font-weight: 500;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba($black, 0.06);
  color: $white-bis;
  overflow: hidden;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);

  .collapsable {
    display: flex;
    align-items: center;
    width: var(--screenIdWidth);
    transition: all 500ms;

    &.collapsed {
      width: 123px;
    }

  }

  &:before {
    background: var(--section-color);
    content: '';
    display: block;
    width: 100%;
    opacity: 0.8;
    z-index: -1;
    height: 100%;
    position: absolute;
  }

  .space {
    width: $height;
    height: $height;
    font-size: 45px;
    text-align: center;
    line-height: 31px;
    background-color: rgba($black, 0.1);

    div {
      width: $height;
      height: $height;
      background: var(--section-color);
      line-height: $height;
      text-transform: uppercase;
    }
  }

  .category {
    min-width: $height;
    min-height: $height;
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 87px;
    height: 87px;
    border: solid 1px var(--section-color);
    border-radius: 50%;
    background: $white-bis;
    color: var(--section-color);

    .icon {
      height: 2.5rem;
      width: 2.5rem;

      svg {
        height: 100%;
      }
    }
  }

  .experience-title {
    overflow: hidden;
    font-size: 40px;
    transition: all 500ms;
    white-space: nowrap;

  }
  
}
</style>
